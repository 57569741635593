<template>
  <div>
    <v-row>
      <v-col cols="12">
        <UsersTable :itemsPerPage="20" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import UsersTable from "@/packages/admin/components/users/UsersTable";

export default {
  name: "Users",
  components: { UsersTable },
  data: () => ({}),
};
</script>

<style scoped></style>
